import "./Footer.css"
import mail from "../../img/mail.svg"
import call from "../../img/call.svg"
import whatsapp from "../../img/whatsapp.svg"

const Footer = () => {

    const scrollTo = (val) => {
        document.getElementById(val).scrollIntoView()
    }

    return (
        <div id="footer" className="footerMain">
            <h2>Apostolic Impact Christian University</h2>
            <p>In christ we are educated.....</p>
            <div>
                <div className="footerAddress">
                    <h4>Uganda address</h4>
                    <p>APOSTOLIC IMPACT CHRISTIAN UNIVERSITY COMBRA CENTRE - JINJA ROAD, NANTABULILILWA STAGE, OFF JOMAYI STONES, UGANDA. <a href="tel:+256755202556">+256755202556</a></p>
                    <h4>Nigeria address</h4>
                    <p>NO 5 OVERFLOWING GRACE STREET, SAWMILL ROAD, OFF BENIN-AUCHI ROAD, BENIN CITY, EDO STATE, NIGERIA</p>
                </div>
                <div className="footerAddress">
                    <h4>Sierra Leone address</h4>
                    <p style={{ textTransform: "uppercase" }}>Tongo field, lower Bambara Chiefdom, Kenema District, Eastern Region, Sierra Leone. <a href="tel:+23278239660">+23278239660</a></p>
                    <h4>Egypt address</h4>
                    <p style={{ textTransform: "uppercase" }}>Cairo Egypt. <a href="tel:+201202857380">+201202857380</a></p>
                </div>
                <div className="footerContact">
                    <h4>Contact us</h4>
                    <p>
                        <img src={mail} alt="" />
                        <a href="mailto:apostolicimpactuniversity@gmail.com">apostolicimpactuniversity@gmail.com</a>
                    </p>
                    <p>
                        <img src={call} alt="" />
                        <a href="tel:+2347037738030">+2347037738030</a>
                    </p>
                    <p>
                        <img src={whatsapp} alt="" />
                        <a href="https://api.whatsapp.com/send?phone=2347037738030">+2347037738030</a>
                    </p>
                </div>
                {/* <div className="footerContact">
                    <h4>Quick Link</h4>
                    <p style={{ cursor: "pointer" }}>
                        Register
                    </p>
                    <p onClick={() => scrollTo("teacher")} style={{ cursor: "pointer" }}>
                        Teacher
                    </p>
                    <p onClick={() => scrollTo("info")} style={{ cursor: "pointer" }}>
                        About Us
                    </p>
                    <p onClick={() => scrollTo("gallery")} style={{ cursor: "pointer" }}>
                        Gallery
                    </p>
                </div> */}
            </div>
        </div>
    )
}

export default Footer