import "./Gallery.css"
import images from "../../data/images"
import { useState } from "react"

const Gallery = () => {
    const [page, setPage] = useState(8)

    const next = () => {
        if (page + 8 > images.length) {
            setPage(images.length)
        } else {
            setPage(page + 8)
        }
        document.getElementById("gallery").scrollIntoView()
    }

    const prev = () => {
        if (page - 8 <= 0) {
            setPage(8)
        } else {
            setPage(page - 8)
        }
        document.getElementById("gallery").scrollIntoView()
    }

    const openImage = (e) => {
        window.open(e.target.src, "_blank")
    }

    return (
        <div id="gallery" className="galleryMain">
            <h2>Gallery</h2>
            <div className="imageRow">
                {
                    images?.slice(page - 8, page)?.map((image, i) => (
                        <div key={i}>
                            <img onClick={openImage} src={image} alt="" />
                        </div>
                    ))
                }
            </div>
            <div className="paginate">
                <button style={{ opacity: page - 8 <= 0 ? "0.5" : "1" }} onClick={prev}>Previous</button>
                <button style={{ opacity: page + 8 > images.length ? "0.5" : "1" }} onClick={next}>Next</button>
            </div>
        </div>
    )
}

export default Gallery