import Footer from "../components/homepage/Footer"
import Gallery from "../components/homepage/Gallery"
import Header from "../components/homepage/Header"
import Info from "../components/homepage/Info"
import Teacher from "../components/homepage/Teacher"

const Homepage = () => {
    return (
        <div>
            <Header />
            <Info />
            <Teacher />
            <Gallery />
            <Footer />
        </div>
    )
}

export default Homepage