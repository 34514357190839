import "./Teacher.css"
import teachers from "../../data/teachers"

const Teacher = () => {
    return (
        <div id="teacher" className="teacherMain">
            <h2>Teachers</h2>
            <div>
                {
                    teachers?.map((teacher, i) => (
                        <div key={i}>
                            <img src={teacher.image} alt="" />
                            <h4>{teacher.name}</h4>
                            <p>{teacher.role}</p>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Teacher