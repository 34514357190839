import img37 from '../img/img37.jpg'
import img38 from '../img/img38.jpg'
import img89 from '../img/img89.jpg'
import img92 from '../img/img92.jpg'
import img93 from '../img/img93.jpg'
import img95 from '../img/img95.jpg'
import img96 from '../img/img96.jpg'

const teachers = [
    {
        name: "Prof Sunday Uahomo",
        role: "Chancellor",
        image: img37
    },
    {
        name: "Dr Jan Jones",
        role: "Dean of student affairs",
        image: img38
    },
    // {
    //     name: "Dr Kathy Watkine",
    //     role: "Dean of Academic Affairs",
    //     image: img89
    // },
    {
        name: "Archbisop Professor Yvonne B Bentley, PhD.s",
        role: "Chancellor. International Kingdom University USA",
        image: img92
    },
    {
        name: "Courage Uahomo",
        role: "Registrar",
        image: img93
    },
    {
        name: "Dr Geert Mommaert",
        role: "Registrar General/International Student Information Officer",
        image: img95
    },
    {
        name: "Dr Pamela Parson",
        role: "Dean of Acamedics",
        image: img96
    },
]

export default teachers