import img1 from '../img/img1.jpg'
import img2 from '../img/img2.jpg'
import img3 from '../img/img3.jpg'
import img4 from '../img/img4.jpg'
import img5 from '../img/img5.jpg'
import img6 from '../img/img6.jpg'
import img7 from '../img/img7.jpg'
import img8 from '../img/img8.jpg'
import img9 from '../img/img9.jpg'
import img10 from '../img/img10.jpg'
import img11 from '../img/img11.jpg'
import img12 from '../img/img12.jpg'
import img13 from '../img/img13.jpg'
import img14 from '../img/img14.jpg'
import img15 from '../img/img15.jpg'
import img16 from '../img/img16.jpg'
import img17 from '../img/img17.jpg'
import img18 from '../img/img18.jpg'
import img19 from '../img/img19.jpg'
import img20 from '../img/img20.jpg'
import img21 from '../img/img21.jpg'
import img22 from '../img/img22.jpg'
import img23 from '../img/img23.jpg'
import img24 from '../img/img24.jpg'
import img25 from '../img/img25.jpg'
import img26 from '../img/img26.jpg'
import img27 from '../img/img27.jpg'
import img28 from '../img/img28.jpg'
import img29 from '../img/img29.jpg'
import img30 from '../img/img30.jpg'
import img31 from '../img/img31.jpg'
import img32 from '../img/img32.jpg'
import img33 from '../img/img33.jpg'
import img34 from '../img/img34.jpg'
import img35 from '../img/img35.jpg'
import img36 from '../img/img36.jpg'
import img39 from '../img/img39.jpg'
import img40 from '../img/img40.jpg'
import img41 from '../img/img41.jpg'
import img42 from '../img/img42.jpg'
import img43 from '../img/img43.jpg'
import img44 from '../img/img44.jpg'
import img45 from '../img/img45.jpg'
import img46 from '../img/img46.jpg'
import img47 from '../img/img47.jpg'
import img48 from '../img/img48.jpg'
import img49 from '../img/img49.jpg'
import img50 from '../img/img50.jpg'
import img51 from '../img/img51.jpg'
import img52 from '../img/img52.jpg'
import img53 from '../img/img53.jpg'
import img54 from '../img/img54.jpg'
import img55 from '../img/img55.jpg'
import img56 from '../img/img56.jpg'
import img57 from '../img/img57.jpg'
import img58 from '../img/img58.jpg'
import img59 from '../img/img59.jpg'
import img60 from '../img/img60.jpg'
import img61 from '../img/img61.jpg'
import img62 from '../img/img62.jpg'
import img63 from '../img/img63.jpg'
import img64 from '../img/img64.jpg'
import img65 from '../img/img65.jpg'
import img66 from '../img/img66.jpg'
import img67 from '../img/img67.jpg'
import img68 from '../img/img68.jpg'
import img69 from '../img/img69.jpg'
import img70 from '../img/img70.jpg'
import img71 from '../img/img71.jpg'
import img72 from '../img/img72.jpg'
import img73 from '../img/img73.jpg'
import img74 from '../img/img74.jpg'
import img75 from '../img/img75.jpg'
import img76 from '../img/img76.jpg'
import img77 from '../img/img77.jpg'
import img78 from '../img/img78.jpg'
import img79 from '../img/img79.jpg'
import img80 from '../img/img80.jpg'
import img81 from '../img/img81.jpg'
import img82 from '../img/img82.jpg'
import img83 from '../img/img83.jpg'
import img84 from '../img/img84.jpg'
import img86 from '../img/img86.jpg'
import img87 from '../img/img87.jpg'
import img88 from '../img/img88.jpg'
import img90 from '../img/img90.jpg'
import img91 from '../img/img91.jpg'
import img94 from '../img/img94.jpg'

const images = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
    img17,
    img18,
    img19,
    img20,
    img21,
    img22,
    img23,
    img24,
    img25,
    img26,
    img27,
    img28,
    img29,
    img30,
    img31,
    img32,
    img33,
    img34,
    img35,
    img36,
    img39,
    img40,
    img41,
    img42,
    img43,
    img44,
    img45,
    img46,
    img47,
    img48,
    img49,
    img50,
    img51,
    img52,
    img53,
    img54,
    img55,
    img56,
    img57,
    img58,
    img59,
    img60,
    img61,
    img62,
    img63,
    img64,
    img65,
    img66,
    img67,
    img68,
    img69,
    img70,
    img71,
    img72,
    img73,
    img74,
    img75,
    img76,
    img77,
    img78,
    img79,
    img80,
    img81,
    img82,
    img83,
    img84,
    img86,
    img87,
    img88,
    img90,
    img91,
    img94,
]

export default images