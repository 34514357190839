import "./Info.css"
import aicuVideo from "../../img/aicu-video.mp4"

const Info = () => {
    return (
        <div id="info" className="infoMain">
            <h4>Study  to show thyself approved unto God, a workman that needeth not to be ashamed, rightly dividing the word of truth.</h4>
            <p>(2 Timothy 2:15)</p>
            <div className="infoVideo">
                <video controls src={aicuVideo}></video>
                <div>
                    <p>Apostolic impact Christian University key objective is to provide sustainable high quality of
                        professional training in various fields of specialty.</p>
                    <p>The University has a mandate to train the Nationals and international students into
                        relevant and skilled future public administration who will propel the future growth and
                        Human development of their respective Countries globally.</p>
                    <p>Key objective is to raise good professionals in the area of leadership and management, community development and empowerment and produce qualify leaders for Africa nations for total transformation</p>
                </div>
            </div>
            <div className="aboutUs">
                <h4>About us</h4>
                <p>The Apostolic impact christian university was founded in the year 2014, by Archbishop Sunday Uahomo, We are a faith based university, It is currently in over 10 countries around the world and offers the following degrees. PHD, DTH, DMin and many more, We exist for the purpose of the development of quality leadership and the development of the human race across the world.
                    <b>
                        <hr /> We have special courses in the following areas:
                        <br /> Certificate in Theology/business Administration (Business office, Administration, leadership and theology),
                        <br /> Certificate in Mission and Cross-Cultural Ministry
                        <br /> Certificate in Discipleship, Evangelism and Church planting
                        <hr /> Diploma in various courses, such as:
                        <br /> Theology
                        <br /> Mission/Ministry
                        <br /> Social Sciences/community relations
                        <br /> Computer Science
                        <br /> Business Administration
                        <hr /> There are special courses:
                        <br /> Masters in Theology/Oil and Gas
                        <br />  Masters in Theology/Mining technology
                        <br /> Masters in Theology/Agriculture
                        <br /> Masters in Theology/Business Administration
                    </b>
                    <hr />
                    <b>ACCREDITATION/PARTNERSHIP</b>
                    <br />Our university have study centres in different countries. Presently we have been able to have accreditation from the Government of Sierra Leone, from the office of Tertiary Education Commission (TEC) and the NCTVA for Diplomas, Higher diplomas and certificate courses. We currently partner and affiliate with International Kingdom University Florida, USA for our Bachelors, Masters and Doctorate degrees awards.
                    <hr />
                    <b>OUR UNIVERSITY INTEGRITY STATEMENT</b>
                    <br /> At Apostolic Impact Christian University, we are fully committed to creating an enabling environment of good integrity that is firmly rooted in our faith based principles. We have unwavering dedication to honesty, transparency and ethical conduct that forms the basic foundation of our community. This institutional integrity statements reflects our pledge to operate with integrity and represent ourselves truthfully and authentically to both our students and the wider public.
                    <hr />
                    <b>OUR CORE PRINCIPLES:</b>
                    <br />
                    <b>ACADEMIC INTEGRITY - </b> In AICU, we uphold the standard of integrity and honesty, that foster and environment, where knowledge is pursued and shared with utmost integrity. Every member of our community is expected to demonstrate these principles in the pursuit.
                    <br />
                    <b>ACCOUNTABILITY - </b> At AICU, we take our commitments and responsibilities seriously. We believe in being transparent about any mistakes. We make promptly, rectify them and consistently striving to learn and grown from their experiences.
                    <br />
                    <b>COMMUNITY ENGAGEMENT - </b>We believe in engaging with the public through open interactions, where we share our mission, achievements and societal impacts. Our dedication to community engagement reflects our commitment towards transparency and accountability.
                    <hr /> The Apostolic Impact christian university is affliliated to International kingdom university USA, it is also accredited in the republic of Sierra Leone.</p>
            </div>
            <div className="infoCourses">
                <h2>We Offer The Following Degree:</h2>
                <div>
                    <div>
                        <h4>Doctorate level</h4>
                        <p>Doctor of theology (DTh)</p>
                        <p>Doctor of ministry (DMin)</p>
                        <p>Doctor of philosophy (Phd)</p>
                    </div>
                    <div>
                        <h4>Diploma level</h4>
                        <p>Diploma in practical Theology</p>
                        <p>Diploma in Theology and Biblical studies</p>
                    </div>
                    <div>
                        <h4>Masters level</h4>
                        <p>Master in Divinity</p>
                        <p>Master in Theology</p>
                        <p>Master in Arts</p>
                        <p>Master in Ministry</p>
                    </div>
                    <div>
                        <h4>Bachelor level</h4>
                        <p>Bachelor in practical Theology</p>
                        <p>Bachelor in Ministry</p>
                        <p>Bachelor in Religious education</p>
                        <p>Bachelor in Theology</p>
                        <p>Bachelor of Arts</p>
                    </div>
                </div>
                <h5>Our various centres include:</h5>
                <h4>UGANDA CAMPUS,
                    SIERRA LEONE CAMPUS,
                    MOZAMBIQUE CAMPUS,
                    GHANA CAMPUS,
                    EGYPT CAMPUS,
                    NIGERIA CAMPUS,
                    LIBERIA CAMPUS</h4>
                <h5>We have two systems of our study:</h5>
                <h4 style={{ textTransform: "uppercase" }}>Physical study at the campus</h4>
                <h4 style={{ textTransform: "uppercase" }}>Online Study</h4>
                <button>Join Us</button>
            </div>
        </div>
    )
}

export default Info