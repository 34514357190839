import "./Header.css"
import logo from "../../img/aicu-logo.png"
import ham from "../../img/hamburger.svg"
import bird from "../../img/bird3.png"

const Header = () => {

    const scrollTo = (val) => {
        document.getElementById(val).scrollIntoView()
    }

    return (
        <div className='mainHeader'>
            <div className='headerNav'>
                <img src={logo} alt="" />
                <img className="hamburger" src={ham} alt="" />
                <div>
                    <p>Home</p>
                    <p onClick={() => scrollTo("teacher")}>Teachers</p>
                    <p onClick={() => scrollTo("info")}>About Us</p>
                    <p onClick={() => scrollTo("footer")}>Contact</p>
                    <p onClick={() => scrollTo("gallery")}>Gallery</p>
                </div>
                <button>Register</button>
            </div>
            <div className="headMainDiv">
                <div>
                    <h2>Apostolic impact christian university</h2>
                    <h4>Affiliated to international kingdom university USA</h4>
                    <h4>Accredited in the Republic of Sierra Leone</h4>
                    <h4>With branches in many countries all over the world</h4>
                    <div>
                        <button>Register</button>
                        <button onClick={() => scrollTo("info")}>Learn More</button>
                    </div>
                </div>
                <div>
                    <img src={logo} alt="" />
                </div>
            </div>
        </div>
    )
}

export default Header